import { useEffect } from "react";
import { Link } from "react-router-dom";
import BoardItem from "../../components/BoardItem";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import { useWhiteboard } from "../../context/WhiteboardContext";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../context/AuthContext";

const HoloWhiteboard = () => {
  const { boards } = useWhiteboard();
  const authContext = useAuth();

  const context = useWhiteboard();

  useEffect(() => {
    context.connect();
  }, []);

  return (
    <Wrapper>
      {boards ? (
        <div className="w-full sm:w-[30rem] h-full flex flex-col items-center justify-center gap-2 max-h-[40rem] fadeInstant">
          <Title text={"Holo Whiteboard"} returnDestination={"/"} />
          <div className="scrollbar paper-inverse flex flex-col justify-start gap-2 w-full h-full overflow-y-auto">
            {boards
              .filter((board) => {
                return (
                  board.visibility !== "private" ||
                  (authContext.user &&
                    (authContext.user.role === "admin" ||
                      board.author.id === authContext.user.id ||
                      board.editor.filter(
                        (element) => element.id === authContext.user.id
                      ).length > 0 ||
                      board.viewer.filter(
                        (element) => element.id === authContext.user.id
                      ).length > 0))
                );
              })
              .map((board) => (
                <BoardItem
                  key={board.uid}
                  name={board.name}
                  id={board.uid}
                  users={board.users || 0}
                  online={board.users && board.users > 0}
                />
              ))}
          </div>
          <Link
            className="mt-2 w-full flex justify-center"
            to={"/whiteboard/new"}
          >
            <button className="btn">Create New Board</button>
          </Link>
        </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default HoloWhiteboard;
