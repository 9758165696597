import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const SignIn = () => {
  const authContext = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = async () => {
    const user = await authContext.signIn(email, password);

    if (user) {
      window.location.assign("/");
    }
  };

  return (
    <Wrapper>
      <div className="w-full sm:w-[30rem] h-full flex flex-col items-center justify-center gap-2 max-h-[40rem] fadeInstant">
        <div className="input-group">
          <label className="input-label">E-Mail</label>
          <input
            type="email"
            className="input w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className="input-group">
          <label className="input-label">Password</label>
          <input
            type="password"
            className="input w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div className="h-2"></div>
        <button className="btn w-full" onClick={signIn}>
          Sign In
        </button>
        <Link to={"/auth/signup"}>
          <button>Sign Up instead</button>
        </Link>
      </div>
    </Wrapper>
  );
};

export default SignIn;
