import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/Root";
import ChooseWhiteboard from "./routes/Whiteboard/ChooseWhiteboard";
import NewWhiteboard from "./routes/Whiteboard/NewWhiteboard";
import Whiteboard from "./routes/Whiteboard/Whiteboard";
import { WhiteboardProvider } from "./context/WhiteboardContext";
import { AuthProvider } from "./context/AuthContext";
import SignIn from "./routes/Auth/SignIn";
import SignUp from "./routes/Auth/SignUp";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Root />
      </AuthProvider>
    ),
  },
  {
    path: "/auth/signin",
    element: (
      <AuthProvider>
        <SignIn />
      </AuthProvider>
    ),
  },
  {
    path: "/auth/signup",
    element: (
      <AuthProvider>
        <SignUp />
      </AuthProvider>
    ),
  },
  {
    path: "/whiteboard",
    element: (
      <AuthProvider>
        <WhiteboardProvider>
          <ChooseWhiteboard />
        </WhiteboardProvider>
      </AuthProvider>
    ),
  },
  {
    path: "/whiteboard/new",
    element: (
      <AuthProvider>
        <WhiteboardProvider>
          <NewWhiteboard />
        </WhiteboardProvider>
      </AuthProvider>
    ),
  },
  {
    path: "/whiteboard/:uid",
    element: (
      <AuthProvider>
        <WhiteboardProvider>
          <Whiteboard />
        </WhiteboardProvider>
      </AuthProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
