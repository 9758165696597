import React from "react";
import { Link } from "react-router-dom";

const BoardItem = ({ id, name, online, users }) => {
  return (
    <Link to={`/whiteboard/${id}`}>
      <div className="paper link cursor-pointer flex justify-between">
        <div>
          <p className="font-medium text-lg line-clamp-1">{name}</p>
          <p className="font-light">{id}</p>
        </div>
        <div>
          <div className="flex gap-2 items-center">
            <p className="text-sm">{users}</p>
            <div
              className={`rounded-full w-3 h-3 ${
                online ? "bg-primary-500" : "bg-background-400"
              }`}
            ></div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BoardItem;
