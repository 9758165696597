const UserItem = ({ user, canSelect, disabled, selected, onChange }) => {
  return (
    <div className="paper flex justify-between items-center">
      <div>
        <p className="font-medium text-base line-clamp-1 ">{user.name}</p>
        <p className="text-base font-light">{user.email}</p>
      </div>
      {canSelect && (
        <div>
          <input
            disabled={disabled}
            checked={selected}
            type="checkbox"
            className="checkbox w-6 h-6"
            onChange={(e) => onChange(e.target.checked)}
          />
        </div>
      )}
    </div>
  );
};

export default UserItem;
