import { useState } from "react";
import { Link } from "react-router-dom";
import { TbTrash } from "react-icons/tb";
import Spinner from "./Spinner";
import { useWhiteboard } from "../context/WhiteboardContext";

const FileItem = ({ boardId, file }) => {
  const [loading, setLoading] = useState(false);

  const context = useWhiteboard();

  const deleteFile = async () => {
    context.connect();
    context.joinBoard(boardId);
    setLoading(true);
    const response = await fetch(
      `/api/whiteboard/${boardId}/file/${file.uid}`,
      {
        method: "DELETE",
      }
    );
    if (response.ok) {
      console.log("file deleted successfully");
    } else {
      setLoading(false);
      console.log("error while deleting file");
    }
  };

  return (
    <div className="paper flex justify-between">
      <a
        target="_blank"
        href={`/api/whiteboard/${boardId}/file/${file.filename}`}
        rel="noreferrer"
      >
        <div>
          <p className="font-medium text-base line-clamp-1 ">
            {file.originalname}
          </p>
        </div>
      </a>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <TbTrash
            onClick={deleteFile}
            className="hover:text-red-600 cursor-pointer transition-colors ease-linear"
            size={24}
          />
        )}
      </div>
    </div>
  );
};

export default FileItem;
