import React, { useEffect, useRef } from "react";

function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref]);
}

const Modal = ({ open, onClose, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClose);

  return (
    <div className="flex">
      {open && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => console.log("hi")}
          ></div>

          <div className="fixed inset-0 z-10 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full justify-center p-4 items-center">
              <div ref={wrapperRef} className="relative">
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
