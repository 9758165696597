import React, { useContext, useState, useEffect } from "react";

const AuthContext = React.createContext({});

export const getSavedUser = () => {
  const savedUser = localStorage.getItem("user");

  if (savedUser) {
    return JSON.parse(savedUser);
  }
  return null;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    setUser(savedUser);
    setLoading(false);
    return () => {};
  }, []);

  const signIn = async (email, password) => {
    const response = await fetch("/api/auth/signin", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    });

    if (response.ok) {
      const newUser = await response.json();
      setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser));

      return newUser;
    }
    return null;
  };

  const signUp = async (email, name, password) => {
    const response = await fetch("/api/auth/signup", {
      method: "POST",
      body: JSON.stringify({ email, password, name }),
      headers: { "Content-Type": "application/json" },
    });

    if (response.ok) {
      const newUser = await response.json();
      setUser(newUser);

      localStorage.setItem("user", JSON.stringify(newUser));

      return newUser;
    }
    return null;
  };

  const signOut = async () => {
    localStorage.removeItem("user");
    setUser(null);
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ loading, user, signIn, signUp, signOut }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
