import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import { useAuth } from "../../context/AuthContext";

const New = () => {
  const authContext = useAuth();

  const navigate = useNavigate();

  const [boardName, setBoardName] = useState("");
  const [visibility, setVisibility] = useState("protected");

  useEffect(() => {
    if (!authContext.user) {
      navigate("/auth/signin");
    }
  }, []);

  const createBoard = async () => {
    if (boardName && visibility && authContext.user) {
      const response = await fetch("/api/whiteboard/new", {
        method: "POST",
        body: JSON.stringify({ name: boardName, visibility }),
        headers: {
          "Content-Type": "application/json",
          authentication: `Bearer ${authContext.user.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        navigate(`/whiteboard/${data.uid}`);
      }
    }
  };

  return (
    <Wrapper>
      <div className="flex flex-col gap-2 w-full sm:w-[30rem] justify-center items-center">
        <Title text={"New Board"} returnDestination={"/whiteboard"} />
        <div className="paper w-full sm:w-[30rem] flex flex-col gap-4">
          <div className="input-group">
            <label className="input-label">Board Name</label>
            <input
              value={boardName}
              onChange={(e) => setBoardName(e.target.value)}
              placeholder="Name"
              className="input"
            />
          </div>
          <div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <input
                  checked={visibility === "public"}
                  onChange={(e) => setVisibility(e.target.value)}
                  name="Visibility"
                  id="public"
                  className="radio"
                  type="radio"
                  value="public"
                />
                <label htmlFor="public" className="font-semibold">
                  Public{" "}
                  <span className="font-light">
                    - Everyone can see and edit this board.
                  </span>
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  checked={visibility === "protected"}
                  onChange={(e) => setVisibility(e.target.value)}
                  name="Visibility"
                  id="protected"
                  className="radio"
                  type="radio"
                  value="protected"
                />
                <label htmlFor="protected" className="font-semibold">
                  Protected{" "}
                  <span className="font-light">
                    - Everyone can see this board. Editing is limited to
                    selected users.
                  </span>
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  checked={visibility === "private"}
                  onChange={(e) => setVisibility(e.target.value)}
                  name="Visibility"
                  id="private"
                  className="radio"
                  type="radio"
                  value="private"
                />
                <label htmlFor="private" className="font-semibold">
                  Private{" "}
                  <span className="font-light">
                    - Only selected users can see and edit this board.
                  </span>
                </label>
              </div>
            </div>
          </div>
          <button onClick={createBoard} className="btn w-full">
            Create Board
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default New;
