import React from "react";
import Wrapper from "../../components/Wrapper";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";

const SignUp = () => {
  const authContext = useAuth();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const signUp = async () => {
    if (email && name && password && password === confirmPassword) {
      const user = await authContext.signUp(email, name, password);

      if (user) {
        window.location.assign("/");
      }
    }
  };

  return (
    <Wrapper>
      <div className="w-full sm:w-[30rem] h-full flex flex-col items-center justify-center gap-2 max-h-[40rem] fadeInstant">
        <div className="input-group">
          <label className="input-label">E-Mail</label>
          <input
            type="email"
            className="input w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className="input-group">
          <label className="input-label">Name</label>
          <input
            type="text"
            className="input w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
        </div>
        <div className="input-group">
          <label className="input-label">Password</label>
          <input
            type="password"
            className="input w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div className="input-group">
          <label className="input-label">Confirm Password</label>
          <input
            type="password"
            className="input w-full"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>
        <div className="h-2"></div>
        <button className="btn w-full" onClick={signUp}>
          Sign Up
        </button>
        <Link to={"/auth/signin"}>
          <button>Sign In instead</button>
        </Link>
      </div>
    </Wrapper>
  );
};

export default SignUp;
