import React, { useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";
import { getSavedUser } from "./AuthContext";

const WhiteboardContext = React.createContext({});

export const useWhiteboard = () => {
  return useContext(WhiteboardContext);
};

const user = getSavedUser();
const token = user ? user.token : null;

const socket = io("/api/whiteboard", {
  autoConnect: false,
  auth: { token },
});

export const WhiteboardProvider = ({ children }) => {
  const [boards, setBoards] = useState(null);

  const findBoard = (uid) => {
    if (boards) {
      return boards.find((board) => board.uid === uid);
    }
    return null;
  };

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const connect = () => {
    if (!socket.connected) {
      socket.on("boards", (data) => {
        setBoards(data);
      });

      socket.connect();
    }
  };

  const disconnect = () => {
    if (socket) {
      socket.disconnect();
    }
  };

  const joinBoard = (uid) => {
    if (socket) {
      socket.emit("join", uid);
    }
  };

  const leaveBoard = (uid) => {
    if (socket) {
      socket.emit("leave", uid);
    }
  };

  return (
    <WhiteboardContext.Provider
      value={{
        connect,
        disconnect,
        joinBoard,
        leaveBoard,
        boards,
        findBoard,
        socket,
      }}
    >
      {children}
    </WhiteboardContext.Provider>
  );
};
