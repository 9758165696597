import React from "react";
import { BsArrow90DegLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const Title = ({ text, returnDestination }) => {
  return (
    <div className="relative w-full">
      {returnDestination && (
        <Link
          to={returnDestination}
          className="absolute left-0 p-[0.4rem] link"
        >
          <BsArrow90DegLeft size={16} />
        </Link>
      )}

      <p className="text-lg text-center w-full line-clamp-1 px-8">{text}</p>
    </div>
  );
};

export default Title;
