/* eslint-disable react-hooks/exhaustive-deps */
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { useEffect, useState } from "react";
import { useWhiteboard } from "../../context/WhiteboardContext";
import Title from "../../components/Title";
import { TbTrash } from "react-icons/tb";
import FileItem from "../../components/FileItem";
import Spinner from "../../components/Spinner";
import { AiFillEdit } from "react-icons/ai";
import { useAuth } from "../../context/AuthContext";
import UserItem from "../../components/UserItem";
import Modal from "../../components/Modal";

const Whiteboard = () => {
  let { uid } = useParams();
  const context = useWhiteboard();
  const authContext = useAuth();
  const [board, setBoard] = useState(null);
  const [uploading, setUploading] = useState(false);

  const currentBoard = context.findBoard(uid);
  const navigate = useNavigate();

  const [showAddUser, setShowAddUser] = useState(false);

  const [allUsers, setAllUsers] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (showAddUser === "editors") {
      setSelectedUsers(board.editor);
    } else if (showAddUser === "viewers") {
      setSelectedUsers(board.viewer);
    } else {
      setSelectedUsers([]);
    }
  }, [showAddUser]);

  const fetchUsers = async () => {
    const response = await fetch("/api/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      setAllUsers(data);
    }
  };

  useEffect(() => {
    context.connect();
    context.joinBoard(uid);

    context.socket.on("board", (data) => {
      setBoard(data);
      if (canAuthor(data)) {
        fetchUsers();
      }
    });

    context.socket.on("boardDeleted", () => {
      navigate("/whiteboard");
    });
    return () => {
      context.leaveBoard(uid);
    };
  }, []);

  const deleteBoard = async () => {
    await fetch("/api/whiteboard/" + uid, { method: "DELETE" });
  };

  const uploadFiles = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      // make sure client is connected
      context.connect();
      context.joinBoard(uid);
      const url = "/api/whiteboard/" + uid + "/files";
      const formData = new FormData();

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append("files", file);
      }

      setUploading(true);
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("upload complete");
      } else {
        console.log(await response.json());
      }
      setUploading(false);
    }
  };

  const canAuthor = (board) => {
    const isAuthor =
      board &&
      board.author &&
      authContext.user &&
      board.author.id === authContext.user.id;

    const isAdmin =
      board && authContext.user && authContext.user.role === "admin";

    return isAuthor || isAdmin;
  };

  const canEdit = (board) => {
    const isAuthor =
      board &&
      board.author &&
      authContext.user &&
      board.author.id === authContext.user.id;

    const isAdmin =
      board && authContext.user && authContext.user.role === "admin";

    const isEditor =
      board &&
      board.editor.filter((element) => element.id === authContext.user.id)
        .length > 0;

    return (
      isAuthor ||
      isAdmin ||
      isEditor ||
      (board && board.visibility === "public")
    );
  };

  const updateUsers = async () => {
    const data = {};

    if (showAddUser === "editors") {
      data.editor = selectedUsers;
    } else if (showAddUser === "viewers") {
      data.viewer = selectedUsers;
    }

    const response = await fetch("/api/whiteboard/" + uid, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authentication: `Bearer ${authContext.user.token}`,
      },
    });
    if (response.ok) {
      setShowAddUser(false);
    }
  };

  const updateVisibility = async (visibility) => {
    const data = { visibility };

    await fetch("/api/whiteboard/" + uid, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authentication: `Bearer ${authContext.user.token}`,
      },
    });
  };

  return (
    <Wrapper>
      {currentBoard && !authContext.loading && (
        <div
          className={`w-full sm:w-[30rem] ${
            canAuthor(board) && "lg:w-[60rem]"
          } h-full flex flex-col items-center gap-2 max-h-[40rem]`}
        >
          <Title
            text={"Board " + currentBoard.name}
            returnDestination={"/whiteboard"}
          />
          <div className="w-full h-full flex flex-col lg:flex-row gap-4">
            <div className="paper w-full h-full flex flex-col gap-4 min-h-[30rem]">
              <div className="flex justify-between w-full">
                <div>
                  <p className="font-medium text-lg">{currentBoard.name}</p>
                  <p className="font-light">{uid}</p>
                </div>
                <div>
                  <div className="flex gap-2 items-center">
                    <p className="text-sm">{currentBoard.users || 0}</p>
                    <div
                      className={`rounded-full w-3 h-3 ${
                        currentBoard.users > 0
                          ? "bg-primary-500"
                          : "bg-background-400"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="scrollbar paper-inverse flex flex-col justify-start gap-2 w-full h-full overflow-y-auto">
                {board &&
                  board.files &&
                  board.files.map((file) => (
                    <FileItem boardId={uid} key={file.uid} file={file} />
                  ))}
              </div>
              {canEdit(board) && (
                <div className="flex justify-between items-center w-full">
                  {uploading ? (
                    <button className="btn" disabled>
                      <Spinner />
                    </button>
                  ) : (
                    <label htmlFor="fileInput">
                      <div className="btn">Upload Models (.glb)</div>
                    </label>
                  )}

                  <input
                    multiple
                    onChange={uploadFiles}
                    id="fileInput"
                    className="hidden"
                    type="file"
                  ></input>
                </div>
              )}
            </div>
            {canAuthor(board) && (
              <div className="w-full h-full paper flex flex-col justify-between min-h-[30rem] gap-4">
                <div className="font-medium flex justify-between items-center">
                  Editors
                  <AiFillEdit
                    size={24}
                    className="link cursor-pointer"
                    onClick={() => setShowAddUser("editors")}
                  />
                </div>
                <div className="scrollbar paper-inverse flex flex-col justify-start gap-2 w-full h-full overflow-y-auto">
                  <UserItem user={board.author} />
                  {board.editor.map((user) => (
                    <UserItem key={user.id + "editor"} user={user} />
                  ))}
                </div>
                <div className="font-medium flex justify-between items-center">
                  Viewers
                  <AiFillEdit
                    size={24}
                    className="link cursor-pointer"
                    onClick={() => setShowAddUser("viewers")}
                  />
                </div>
                <div className="scrollbar paper-inverse flex flex-col justify-start gap-2 w-full h-full overflow-y-auto">
                  {board.viewer.map((user) => (
                    <UserItem key={user.id + "editor"} user={user} />
                  ))}
                </div>
                <div className="flex justify-between items-center gap-4">
                  <select
                    className="input w-full"
                    value={board.visibility}
                    onChange={(e) => updateVisibility(e.target.value)}
                  >
                    <option value="public">Public</option>
                    <option value="protected">Protected</option>
                    <option value="private">Private</option>
                  </select>
                  <button className="btn btn-danger" onClick={deleteBoard}>
                    <TbTrash size={24} />
                  </button>
                </div>
              </div>
            )}
          </div>
          <Modal open={showAddUser} onClose={() => setShowAddUser(false)}>
            <div className="w-screen sm:w-[30rem] p-2 justify-center items-center">
              <div className="w-full h-full paper flex flex-col gap-4">
                <div className="w-full">
                  {showAddUser === "editors" ? (
                    <p className="font-medium">Editors</p>
                  ) : (
                    <p className="font-medium">Viewers</p>
                  )}
                </div>
                <div className="scrollbar paper-inverse flex flex-col justify-start gap-2 w-full h-[30rem] overflow-y-auto">
                  {allUsers.map((user) => (
                    <UserItem
                      key={user.id}
                      user={user}
                      canSelect={true}
                      selected={
                        user.id === board.author.id ||
                        selectedUsers.filter((element) => {
                          return element.id === user.id;
                        }).length > 0
                      }
                      disabled={user.id === board.author.id}
                      onChange={(selected) => {
                        if (selected) {
                          setSelectedUsers([...selectedUsers, user]);
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (element) => element.id !== user.id
                            )
                          );
                        }
                      }}
                    />
                  ))}
                </div>
                <div className="w-full flex justify-between">
                  <button className="btn" onClick={updateUsers}>
                    Confirm
                  </button>
                  <button
                    className="link font-medium p-2"
                    onClick={() => setShowAddUser(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </Wrapper>
  );
};

export default Whiteboard;
