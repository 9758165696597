import React from "react";
import { Link } from "react-router-dom";

const AppItem = ({ title, url, icon, disabled }) => {
  return (
    <Link className={`${disabled && "pointer-events-none"}`} to={url}>
      <div className="flex flex-col items-center gap-2">
        <div
          className={`flex flex-col w-[10rem] h-[10rem] items-center justify-center  ${
            disabled
              ? "bg-background-200 border border-background-200 text-background-400 shadow-inner rounded-md"
              : "paper link hover:-translate-y-1 hover:shadow-md"
          }`}
        >
          <div className="p-4 h-[6rem] w-[6rem]">{icon}</div>
        </div>
        <p className="text-center text-base">{title}</p>
      </div>
    </Link>
  );
};

export default AppItem;
