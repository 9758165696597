import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Wrapper = ({ children }) => {
  const authContext = useAuth();

  return (
    <div className="absolute inset-0">
      <div className="h-full flex justify-between items-center flex-col w-full">
        <div className=" bg-background-50 shadow-sm w-full">
          <div className="container px-4 flex justify-between items-center">
            <Link to={"/"}>
              <div className="font-base text-lg text-center flex items-center gap-1 link m-3">
                Holo<div className=" font-medium inline text-xl">4</div>Edu
              </div>
            </Link>
            {authContext.user ? (
              <button className="link" onClick={() => authContext.signOut()}>
                {authContext.user.email}
              </button>
            ) : (
              <div className="flex items-center gap-4">
                <Link to={"/auth/signup"}>
                  <div className="text-sm font-medium link">Sign Up</div>
                </Link>
                <Link to={"/auth/signin"}>
                  <button className="btn-sm">Sign In</button>
                </Link>
              </div>
            )}
          </div>
        </div>
        {children && (
          <div className="w-full h-full flex flex-col items-center justify-center fadeIn p-4">
            {children}
          </div>
        )}

        <div></div>
      </div>
    </div>
  );
};

export default Wrapper;
