import React from "react";
import AppItem from "../components/AppItem";
import Wrapper from "../components/Wrapper";
import { BiChalkboard } from "react-icons/bi";
import { BsMinecart } from "react-icons/bs";
import { AiOutlineBuild } from "react-icons/ai";
import { useWhiteboard } from "../context/WhiteboardContext";
import { useEffect } from "react";
import Title from "../components/Title";

const Root = () => {
  return (
    <Wrapper>
      <div className="flex flex-col gap-4">
        <Title text={"App Selection"} />
        <div className="flex flex-wrap justify-center gap-4">
          <AppItem
            url={"/whiteboard"}
            title={"Holo Whiteboard"}
            icon={<BiChalkboard className="w-full h-full" />}
          />
          <AppItem
            disabled
            title={"Mining Game"}
            icon={<BsMinecart className="w-full h-full" />}
          />
          <AppItem
            disabled
            title={"Block Builder"}
            icon={<AiOutlineBuild className="w-full h-full" />}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Root;
